<template>
  <!-- begin::MyVehicles via Excel -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("MY_VEHICLES.EXCEL.TITLE_2") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <div class="search-info my-6">
        <div class="row mt-3">
          <div class="col-lg-12 mb-6">
            <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
            {{ $t("MY_VEHICLES.EXCEL.INFO_GENERAL") }}
          </div>

          <div class="col-lg-6 px-3">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-primary">
                  {{ $t("MY_VEHICLES.EXCEL.INFO_STATUS") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table id="StatusesTable">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-primary">
                            {{ $t("MY_VEHICLES.EXCEL.STATUS_ID") }}
                          </th>
                          <th class="text-primary">
                            {{ $t("MY_VEHICLES.EXCEL.STATUS_NAME") }}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="vs in VehicleStatusOptions"
                          :key="vs.VehicleStatusID"
                        >
                          <td style="width: 25%">
                            <strong>{{ vs.VehicleStatusID }}</strong>
                          </td>
                          <td>
                            {{ vs.Name }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div class="col-lg-6 px-3">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-primary">
                  {{ $t("MY_VEHICLES.EXCEL.INFO_MODEL") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <VehicleModelPicker
                    :initial-model.sync="InitialVehicleModelData"
                    :show-type-id="false"
                    :show-model-id="true"
                    :show-year-field="false"
                    :fields-per-row="1"
                    :trigger-validate-picker="false"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>

      <div class="row my-9">
        <div class="col-lg-12 text-center">
          <button
            class="btn btn-pill button-form btn-primary mr-9 px-9"
            :disabled="Downloading"
            @click="downloadTemplate"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("MY_VEHICLES.EXCEL.DOWNLOAD") }}
          </button>

          <span v-if="!Uploading">
            <input
              type="file"
              ref="inputFileExcel"
              style="display: none"
              @change="uploadExcel($event.target.files)"
              accept=".xlsx"
            />
            <button
              class="btn btn-pill button-form btn-tertiary mr-9 px-9"
              @click="$refs.inputFileExcel.click()"
              :disabled="Uploading"
            >
              <inline-svg src="/media/icons/upload-alt-2.svg" class="mr-3" />
              {{ $t("MY_VEHICLES.EXCEL.UPLOAD") }}
            </button>
          </span>

          <button
            v-else
            class="btn btn-pill button-form btn-tertiary mr-9 px-9"
            disabled
          >
            <inline-svg src="/media/icons/upload-alt-2.svg" class="mr-3" />
            {{ $t("GENERIC.PROCESSING_WAIT") }}...
          </button>

          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="$router.push('/manager/myvehicles')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>
        </div>
      </div>

      <div v-if="ShowResultsTable && ResultsDetails">
        <div class="row my-6">
          <div class="col-lg-12">
            <div class="search-info">
              <inline-svg
                class="mx-3"
                src="/media/icons/help-alt-5-success.svg"
              />
              <strong>
                {{
                  $t("MY_VEHICLES.EXCEL.RESULTS") +
                    ": " +
                    $t("MY_VEHICLES.EXCEL.CREATED_ROWS", {
                      count: ResultsDetails.CreatedRows
                    }) +
                    ", " +
                    $t("MY_VEHICLES.EXCEL.UPDATED_ROWS", {
                      count: ResultsDetails.UpdatedRows
                    }) +
                    ", " +
                    $t("MY_VEHICLES.EXCEL.ERROR_ROWS", {
                      count: ResultsDetails.ErrorRows
                    })
                }}
              </strong>
            </div>
          </div>
        </div>

        <div v-if="ResultsDetails.ErrorRows > 0" class="row">
          <v-simple-table id="ResultErrorsTable" class="mx-6">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-primary">
                    {{ $t("MY_VEHICLES.EXCEL.ROW") }}
                  </th>
                  <th class="text-primary">
                    {{ $t("MY_VEHICLES.EXCEL.CAUSE") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in ResultsDetails.ErrorList"
                  :key="index"
                >
                  <td style="width: 25%">
                    <strong>{{ item.Row }}</strong>
                  </td>
                  <td>
                    {{ $t("MY_VEHICLES.EXCEL.ERROR_" + item.Code) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="ResultsDetails.ErrorRows > 0" class="row my-6 mx-3">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="$router.push('/manager/myvehicles')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::MyVehicles via Excel -->
</template>

<style scoped>
#StatusesTable th,
#ResultErrorsTable th {
  font-size: 1em;
  font-weight: bolder;
}
</style>

<script>
import VehicleService from "@/core/services/api/v2/vehicle.service";
import VehicleStatusService from "@/core/services/api/v2/vehiclestatus.service";

import VehicleModelPicker from "@/view/pages/manager/vehicles/VehicleModelPicker.vue";

export default {
  name: "MyVehicleExcel",
  components: {
    VehicleModelPicker
  },
  data() {
    return {
      VehicleStatusOptions: [],
      InitialVehicleModelData: null,
      Downloading: false,
      Uploading: false,
      ShowResultsTable: false,
      ResultsDetails: null
    };
  },
  mounted() {
    this.loadVehicleStatusOptions();
  },
  methods: {
    loadVehicleStatusOptions() {
      this.VehicleStatusOptions = [];
      VehicleStatusService.listAll().then(response => {
        this.VehicleStatusOptions = response;

        this.InitialVehicleModelData = {
          VehicleModelID: 0,
          VehicleType: null,
          Brand: "",
          Model: "",
          Version: "",
          Year: ""
        };
      });
    },

    downloadTemplate() {
      // Disable download button, because this may take a while
      this.Downloading = true;

      VehicleService.downloadExcelTemplate().then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t("MY_VEHICLES.EXCEL.DOWNLOAD_NAME");
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.Downloading = false;
      });
    },

    uploadExcel(newFile) {
      if (newFile) {
        // Might re-check MIME-type, but must rely on file extension, easy to tamper - check on Laravel side

        // Disable submit button & results table
        this.Uploading = true;
        this.ShowResultsTable = false;
        this.ResultsDetails = [];

        VehicleService.uploadExcel(newFile[0]).then(rawResponse => {
          if (rawResponse.CreatedRows === undefined) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (rawResponse === 400) {
              whyError = this.$i18n.t("MY_VEHICLES.EXCEL.ERROR_400");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.ResultsDetails = rawResponse;
            this.ShowResultsTable = true;

            this.$bvToast.toast(" ", {
              title: this.$i18n.t("MY_VEHICLES.EXCEL.UPLOADED_OK"),
              variant: "success",
              solid: true
            });
          }

          // Reset file & button
          this.Uploading = false;
          this.$nextTick(() => {
            this.$refs.inputFileExcel.value = null;
          });
        });
      }
    }
  }
};
</script>
